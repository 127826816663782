.social {
    position: relative;
    width: 100%;
    height: 400px;
    margin-bottom: 150px;
    background: url("../../assets/images/header.jpg");
    background-size: contain;
    background-attachment: fixed;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.social-tint {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}

.social-tint-heading {
    font-size: 3em;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
}

.social-accounts-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin-top: 80px;
}

.social-accounts-card {
    text-align: center;
    width: 25%;
}

.social-accounts-card i {
    font-size: 3em;
    padding-bottom: 3px;
}

.social-media-platform {
    font-size: 1.2em;
    padding-bottom: 20px;
}

.social-media-follow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 80%;
    height: 40px;
    border-radius: 10px;
    background: #f45d22;
    color: #000;
    font-weight: bold;
    font-style: 1.2em;
    text-decoration: none;
}