.contact {
    position: relative;
    width: 100%;
    margin-bottom: 150px;
}

.contact-table {
    display: table;
    width: 100%;
    margin: 0 auto;
    color: #fff;
}

.contact-table-left {
    display: table-cell;
    vertical-align: top;
    width: 50%;
}

.contact-table-right {
    display: table-cell;
    vertical-align: top;
    width: 50%;
}

.contact-about {
    width: 70%;
}

.contact-info {
    padding-bottom: 20px;
    font-size: 1.3em;
    line-height: 150%;
}

.contact-phone {
    color: #f45d22;
}

.contact-info i {
    padding-right: 10px;
    color: rgba(255, 255, 255, 0.6);
}

.map-frame {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
}