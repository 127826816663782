.galery {
    position: relative;
    width: 100%;
    margin-top: 100px;
}

.galery-back-btn {
    padding: 10px 20px;
    border-radius: 5px;
    background: #000;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 1.5em;
    border: none;
    outline: none;
    margin-bottom: 40px;
    cursor: pointer;
}

.galery-back-btn i {
    margin-right: 10px;
}