.nav {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 10px 0;
    background: rgba(0,0,0,0.8);
    box-shadow: 0 18px 40px -12px rgba(0,0,0,1);
    color: #fff;
}

.logo {
    font-family: "Germanica";
    font-size: 2.5em;
    letter-spacing: 2px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.2em;
}

.menu-item {
    position: relative;
    margin-left: 40px;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    transition: 0.25s;
    cursor: pointer;
}

.menu-item:hover {
    color: rgba(255, 255, 255, 0.6);
}

.menu-icon {
    display: none;
}

.mobile-menu {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes mobileMenu {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.mobile-menu-active {
    display: flex;
    animation: mobileMenu 0.35s ease-in forwards;
}

.mobile-menu-item {
    font-size: 2em;
    padding: 14px 0;
}

.mobile-menu-heading {
    font-size: 2em;
    font-family: 'Indie Flower', cursive;
    margin-bottom: 10px;
    background: -webkit-linear-gradient(#f45d22 50%, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 15px;
}