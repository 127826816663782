@font-face {font-family: "Germanica"; src: url("//db.onlinewebfonts.com/t/d841eecc2a45a71afca6b06bfd0f2347.eot"); src: url("//db.onlinewebfonts.com/t/d841eecc2a45a71afca6b06bfd0f2347.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/d841eecc2a45a71afca6b06bfd0f2347.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/d841eecc2a45a71afca6b06bfd0f2347.woff") format("woff"), url("//db.onlinewebfonts.com/t/d841eecc2a45a71afca6b06bfd0f2347.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/d841eecc2a45a71afca6b06bfd0f2347.svg#Germanica") format("svg"); }

.App {
  width: 100%;
}

.container {
  width: 60%;
  margin: 0 auto;
}

.heading {
    font-size: 2em;
    font-weight: bold;
    padding-bottom: 30px;
    color: #fff;
}

/* Responsive */

/* MOBILE CSS */

@media screen and (max-width:767px) and (min-width:100px) {

  .container {
    width: 90%;
  }

  .menu-item, .slider-prev, .slider-next {
    display: none;
  }

  .nav {
    justify-content: space-between;
    width: 100%;
    padding: 10px 5%;
    box-sizing: border-box;
  }

  .menu-icon {
    display: block;
    font-size: 1.5em;
  }

  .header-heading {
    font-size: 3em;
    display: none;
  }

  .header-desc {
    width: 100%;
    font-size: 1.1em;
    padding: 20px;
    box-shadow: none;
    background: transparent;
  }

  .team-card {
    width: 100%;
  }

  .social {
    height: auto;
  }

  .social-tint {
    padding: 20px 0;
  }

  .social-tint-heading {
    font-size: 2.5em;
  }

  .social-accounts-flex {
    flex-wrap: wrap;
    width: 90%;
  }

  .social-accounts-card {
    width: 40%;
    margin-bottom: 60px;
  }

  .portfolio-card {
    width: 23%;
    height: 90px;
    margin-bottom: 15px;
  }

  .contact-table-left, .contact-table-right {
    display: table-row;
  }

  .contact-table-right .heading {
    margin-top: 60px;
  }

  .contact-about {
    width: 100%;
  }

  .footer-table {
    flex-wrap: wrap;
    padding: 80px 0 50px 0;
  }

  .footer-cell {
    width: 100%;
    margin-bottom: 40px;
  }

  .footer-bottom-table {
    flex-wrap: wrap;
  }

  .footer-bottom-cell {
    width: 100%;
    text-align: center;
  }

  .social-media-list {
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0 auto;
    width: 60%;
    margin-top: 20px;
  }

  .social-media-list-item {
    margin-left: 0;
  }

  .slider, .team, .social, .portfolio, .contact {
    margin-bottom: 70px;
  }

  .slider-modal-image-wrapper, .portfolio-modal-image-wrapper {
    width: 90%;
    height: 300px;
  }

  .team-modal-inner {
    width: 90%;
    height: 500px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .team-modal-image-wrapper {
    width: 100%;
    height: 50%;
  }

  .team-modal-desc {
    width: 100%;
    margin-left: 0;
  }

  .galery {
    margin-top: 20px;
  }
      
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .container {
    width: 90%;
  }

  .menu-item, .slider-prev, .slider-next {
    display: none;
  }

  .nav {
    justify-content: space-between;
    width: 100%;
    padding: 10px 5%;
    box-sizing: border-box;
  }

  .menu-icon {
    display: block;
    font-size: 1.5em;
  }

  .header-heading {
    font-size: 3em;
    display: none;
  }

  .header-desc {
    width: 80%;
    font-size: 1.4em;
    padding: 20px;
    box-shadow: none;
    background: transparent;
  }

  .team-card {
    width: 48%;
  }

  .social {
    height: auto;
  }

  .social-tint {
    padding: 20px 0;
  }

  .social-tint-heading {
    font-size: 2.5em;
  }

  .social-accounts-flex {
    flex-wrap: wrap;
    width: 70%;
  }

  .social-accounts-card {
    width: 40%;
    margin-bottom: 60px;
  }

  .portfolio-card {
    width: 23%;
    height: 90px;
    margin-bottom: 15px;
  }

  .contact-table-left, .contact-table-right {
    display: table-row;
  }

  .contact-table-right .heading {
    margin-top: 60px;
  }

  .contact-about {
    width: 100%;
  }

  .footer-table {
    flex-wrap: wrap;
    padding: 80px 0 50px 0;
  }

  .footer-cell {
    width: 100%;
    margin-bottom: 40px;
  }

  .footer-bottom-table {
    flex-wrap: wrap;
  }

  .footer-bottom-cell {
    width: 100%;
    text-align: center;
  }

  .social-media-list {
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0 auto;
    width: 60%;
    margin-top: 20px;
  }

  .social-media-list-item {
    margin-left: 0;
  }

  .slider, .team, .social, .portfolio, .contact {
    margin-bottom: 70px;
  }

  .slider-modal-image-wrapper, .portfolio-modal-image-wrapper {
    width: 90%;
    height: 300px;
  }

  .team-modal-inner {
    width: 90%;
    height: 500px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .team-modal-image-wrapper {
    width: 100%;
    height: 50%;
  }

  .team-modal-desc {
    width: 100%;
    margin-left: 0;
  }

  .galery {
    margin-top: 20px;
  }
}
                                                          
                              
@media screen and (max-width:1024px) and (min-width:992px) { 
  

                      
}
      
@media screen and (max-width:1366px) and (min-width:1025px) { 

  .container {
    width: 80%;
  }

  .header-heading {
    font-size: 6.3em;
  }

  .header-desc {
    width: 40%;
  }

}
  

.panel {
  color: white;
}

.panel input, .panel textarea {
  width: 100%;
}