.portfolio {
    position: relative;
    width: 100%;
    margin-bottom: 150px;
}

.portfolio-modal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
}

.portfolio-modal-active {
    display: flex;
    animation: portfolioModal 0.25s ease-in forwards;
}

@keyframes portfolioModal {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.portfolio-modal-image-wrapper {
    position: relative;
    width: 50%;
    height: 600px;
}

.portfolio-modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
}

.portfolio-flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.portfolio-card {
    position: relative;
    width: 18%;
    height: 160px;
    background: #000;
    margin-bottom: 30px;
    margin-right: 2%;
    border-radius: 5px;
    cursor: pointer;
}

.portfolio-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.portfolio-detail-btn {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background: #f45d22;
    font-weight: 600;
    font-size: 1.3em;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}