.header {
    position: relative;
    width: 100%;
    height: 100vh;
}

.header-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0,0,0,0.3), #0f0e12 75%);
    color: #fff;
}

.header-inner-tint {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #0f0e12, transparent);
}

.header-tint-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header-heading {
    font-size: 8em;
    font-family: "Germanica";
    margin-bottom: 10px;
    background: -webkit-linear-gradient(#f45d22 50%, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header-desc {
    width: 35%;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 10px;
    background: rgba(0,0,0,0.4);
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.8);
    text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-size: 1.3em;
    line-height: 150%;
}

.header-btn {
    text-align: center;
    margin-top: 90px;
    padding: 20px 40px;
    border-radius: 10px;
    font-size: 1.4em;
    font-weight: 600;
    border: 2px solid rgba(244, 93, 34, 0.6);;
    background: rgba(244, 93, 34, 0.2);
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.8);
    cursor: pointer;
}

.header-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    font-size: 2em;
    animation: arrows 2.5s ease-in infinite;
}

.header-icon-wrapper i:last-child {
    margin-top: -14px;
}

@keyframes arrows {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}