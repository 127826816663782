.team {
    position: relative;
    width: 100%;
    margin-bottom: 150px;
}

.team-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.team-modal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
}

.team-modal-active {
    display: flex;
    animation: teamModal 0.25s ease-in forwards;
}

@keyframes teamModal {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.team-modal-inner {
    width: 50%;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}

.team-modal-image-wrapper {
    position: relative;
    width: 40%;
    height: 100%;
}

.team-modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
}

.team-modal-desc {
    width: 50%;
    max-height: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-left: 20px;
    overflow: auto;
}

/* width */
.team-modal-desc::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.team-modal-desc::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.team-modal-desc::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.team-modal-desc::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.team-modal-name {
    width: 100%;
    text-align: left;
    font-size: 1.3em;
    font-weight: 600;
    padding-bottom: 20px;
}

.team-modal-bio {
    font-size: 1.1em;
    line-height: 150%;
}

.team-card {
    position: relative;
    width: 23%;
    margin-right: 2%;
    height: 300px;
    border-radius: 10px;
    background: #000;
    margin-bottom: 80px;
    cursor: pointer;
}

.team-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
    filter: grayscale(100%);
    transition: 0.25s;
}

.team-card-image:hover {
    filter: unset;
}

.team-card-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.9);
    text-align: center;
    padding: 5px;
}

.team-card-name {
    font-size: 1.1em;
    color: #fff;
    padding-bottom: 3px;
}

.team-card-title {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.7);
}

.team-card-details {
    width: 100%;
    background: #f45d22;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
    text-align: center;
    border-radius: 0 0 10px 10px;
    padding: 8px 0;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    margin-top: 5px;
}