.slider {
    position: relative;
    width: 100%;
    margin-bottom: 150px;
}

.slider-modal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
}

.slider-modal-active {
    display: flex;
    animation: sliderModal 0.25s ease-in forwards;
}

@keyframes sliderModal {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.slider-modal-image-wrapper {
    position: relative;
    width: 50%;
    height: 600px;
}

.slider-modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
}

.slider-flex {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
}

.slider-flex::-webkit-scrollbar {
    display: none;
}

.slider-prev, .slider-next {
    position: absolute;
    font-size: 3em;
    color: #fff;
    top: 50%;
    cursor: pointer;
    transition: 0.25s;
}

.slider-prev:hover, .slider-next:hover {
    color: rgba(255, 255, 255, 0.6);
}

.slider-prev {
    left: -80px;
}

.slider-next {
    right: -80px;
}

.slider-card {
    position: relative;
    flex: 0 0 auto;
    width: 150px;
    height: 150px;
    background: #000;
    margin: 0 30px 0 0;
    border-radius: 10px;
    box-shadow: 0 18px 40px -12px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.slider-card-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}