/* Footer Starting */
#footer {
    position: relative;
    width: 100%;
    height: auto;
    background: rgb(0, 0, 0, 0.3);
}

.footer-table {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 100px 0;
    width: 100%;
}

.footer-bottom {
    width: 100%;
    padding: 30px 0;
    background: #000;
}

.footer-bottom-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
}

.footer-bottom-cell {
    color: rgba(255, 255, 255, 0.6);
}

.footer-cell {
    color: #fff;
    width: 25%;
}

.footer-contact-info {
    padding-bottom: 10px;
    line-height: 150%;
}

.footer-contact-phone {
    color: #f45d22;
}

.footer-contact-info i {
    padding-right: 10px;
    color: rgba(255, 255, 255, 0.6);
}

.footer-services-list {
    width: 100%;
    list-style: none;
}

.footer-services-list-item {
    padding-bottom: 10px;
}

.footer-bottom-cell span {
    padding: 0 5px;
}

.footer-logo {
    font-size: 2.5em;
    letter-spacing: 2px;
    font-weight: 600;
    padding-bottom: 30px;
    font-family: "Germanica";
}

.footer-about {
    font-size: 1em;
    color: rgba(255, 255, 255, 0.6);
    line-height: 150%;
}

.footer-heading {
    font-size: 1.6em;
    font-weight: 600;
    padding-bottom: 30px;
    color: #f45d22;
}

.social-media-list {
    list-style: none;
    display: flex;
}

.social-media-list-item {
    margin-left: 20px;
    font-size: 1.6em;
    transition: 0.25s;
}

.social-media-list-item:hover {
    color: #f45d22;
}